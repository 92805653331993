// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-entreprise-js": () => import("./../../../src/pages/entreprise.js" /* webpackChunkName: "component---src-pages-entreprise-js" */),
  "component---src-pages-etudes-marches-js": () => import("./../../../src/pages/etudes-marches.js" /* webpackChunkName: "component---src-pages-etudes-marches-js" */),
  "component---src-pages-habitat-js": () => import("./../../../src/pages/habitat.js" /* webpackChunkName: "component---src-pages-habitat-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prospective-js": () => import("./../../../src/pages/prospective.js" /* webpackChunkName: "component---src-pages-prospective-js" */),
  "component---src-pages-qui-sommes-nous-js": () => import("./../../../src/pages/qui-sommes-nous.js" /* webpackChunkName: "component---src-pages-qui-sommes-nous-js" */)
}

